import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import Offer from "../images/offer.svg"
import OfferEN from "../images/offer_ang.svg"
import OfferDE from "../images/offer_de.svg"
import Line from "../images/line.svg"
import MiddleRoad from "../images/middle_road.jpg"
import Clock from "../images/clock.svg"
import Truck from "../images/truck.svg"
import Like from "../images/like.svg"
import Download from "../images/download.svg"
import Ship from "../images/middle_ship.jpg"
import HowWeMakeIt from "../images/how_we_makeit.svg"
import HowWeMakeItEN from "../images/how_do_we_ang.svg"
import HowWeMakeItDE from "../images/how_do_we_de.svg"
import MainHeader from "../images/header.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const list_line_item = {
  background: `url(${Line}) no-repeat center left`,
  padding: "3px 0px 3px 30px",
  listStyle: "none",
}

const middle_road = {
  background: `url(${MiddleRoad}) no-repeat center center`,
  backgroundSize: "cover",
}

const middle_ship = {
  marginTop: "3rem",
  background: `url(${Ship}) no-repeat center center`,
  backgroundSize: "cover",
}

const pink_section = {
  position: "absolute",
  bottom: "-20%",
  margin: "0 auto",
  left: "0",
  right: "0",
}

const heade_background = {
  background: `url(${MainHeader}) top right no-repeat`,
  backgroundSize: "50% 100%",
  marginTop: "2rem",
  height: "89vh",
  display: "flex",
  alignItems: "center",
}

const heade_background_mobile = {
  background: `url(${MainHeader}) center center`,
  backgroundSize: "cover",
  marginTop: "2rem",
  height: "89vh",
  display: "flex",
  alignItems: "center",
}

const ship_card = {
  margin: "0 40rem",
  padding: "4rem",
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { t, i18n } = this.props
    console.log(i18n.language)
    let OfferImage = Offer
    let HowWeMakeItImage = HowWeMakeIt

    if (i18n.language === "pl") {
      OfferImage = Offer
      HowWeMakeItImage = HowWeMakeIt
    } else if (i18n.language === "en") {
      OfferImage = OfferEN
      HowWeMakeItImage = HowWeMakeItEN
    } else if (i18n.language === "de") {
      OfferImage = OfferDE
      HowWeMakeItImage = HowWeMakeItDE
    }

    return (
      <Layout>
        <SEO
          title="Strona glówna"
          description="Transport Międzynarodowy Katowice, Spedycja Międzynarodowa - Fastigo.pl"
        />
        <section className="hero">
          <div
            className="hero-body"
            style={
              this.state.width <= 1023
                ? heade_background_mobile
                : heade_background
            }
          >
            <div className="container">
              <div className="columns">
                <div
                  className="column is-flex"
                  style={
                    this.state.width <= 1023
                      ? {
                          background: "white",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }
                      : { flexDirection: "column", justifyContent: "flex-end" }
                  }
                >
                  <div className="content">
                    <p className="title is-size-3-mobile is-1 is-spaced has-text-weight-bold is-family-secondary is-marginless">
                      {t("HEADER_TITLE")}
                    </p>
                    <p className="subtitle is-size-6-mobile">
                      {t("HEADER_TITLE_BOTTOM")}
                    </p>
                  </div>
                  <br />
                  <div className="columns" style={{ paddingBottom: "0.5rem" }}>
                    <div className="column">
                      <Link
                        to="/#o-nas"
                        className="button is-primary is-normal is-fullwidth is-radiusless has-text-weight-bold"
                      >
                        {t("BUTTON_MORE")}
                      </Link>
                    </div>
                    <div className="column">
                      <Link
                        to="/zlecenia-spedycyjne"
                        className="button is-primary is-normal is-outlined is-fullwidth is-radiusless has-text-weight-bold"
                      >
                        {t("BUTTON_TRANSPORT").toUpperCase()}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="column">
                  {/* <img src={MainHeader} alt="fastigo-header"/> */}
                </div>
              </div>
            </div>
          </div>
          <div className="hero-foot">
            <div className="container">
              <nav className="tabs is-right">
                <div className="container">
                  <ul>
                    <li style={{ margin: "20px" }}>
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        size="2x"
                        color="#E30C5C"
                      />
                    </li>
                    <li style={{ margin: "20px" }}>
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size="2x"
                        color="#E30C5C"
                      />
                    </li>
                    <li style={{ margin: "20px" }}>
                      <a
                        className="is-paddingless"
                        href="https://www.facebook.com/fastigologistics"
                        style={{ borderBottom: "none" }}
                      >
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          size="2x"
                          color="#E30C5C"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </section>

        <section id="o-nas" className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <img
                  src={OfferImage}
                  style={this.state.width >= 768 ? { float: "right" } : null}
                  alt="fastigo-offer"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <p
                    className="title is-family-secondary"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    {t("WE_OFFER")}
                  </p>
                  <ul style={{ margin: "0" }}>
                    <li style={list_line_item}>
                      {t("SEMITRAILERS")}{" "}
                      <strong>{t("COOLER").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("SEMITRAILERS")}{" "}
                      <strong>{t("MEGA").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("SEMITRAILERS")}{" "}
                      <strong>{t("STANDARD").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("SEMITRAILERS")}{" "}
                      <strong>{t("PLATFORM").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("CARTS")} <strong>{t("EXPRESS").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("INTERCONTINENTAL")}{" "}
                      <strong>{t("WATERTRAILERS").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      {t("MATERIAL")}{" "}
                      <strong>{t("LOOSE").toUpperCase()}</strong>
                    </li>
                    <li style={list_line_item}>
                      <strong> {t("WASTE").toUpperCase()}</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div className="content">
                  <p>{t("ABOUT")}</p>
                  <p className="has-text-primary has-text-weight-bold">
                    {t("ABOUT_PINK")}
                  </p>
                </div>
                <div className="columns">
                  <div className="column">
                    <Link
                      to="/#spedycja-drogowa"
                      className="button is-primary is-normal is-outlined is-fullwidth is-radiusless has-text-weight-bold"
                    >
                      {t("BUTTON_ROAD")}
                    </Link>
                  </div>
                  <div className="column">
                    <Link
                      to="/#spedycja-morska"
                      className="button is-primary is-normal is-outlined is-fullwidth is-radiusless has-text-weight-bold "
                    >
                      {t("BUTTON_SEA")}
                    </Link>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Link
                      to="/#express"
                      className="button is-primary is-normal is-outlined is-fullwidth is-radiusless has-text-weight-bold"
                    >
                      {t("BUTTON_EXPRESS")}
                    </Link>
                  </div>
                  <div className="column">
                    <Link
                      to="/#express"
                      className="button is-primary is-normal is-outlined is-fullwidth is-radiusless has-text-weight-bold"
                    >
                      {t("BUTTON_STORAGE")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="spedycja-drogowa"
          className="hero is-medium"
          style={middle_road}
        >
          <div className="hero-body">
            <div className="container"></div>
          </div>
          <div
            className="hero-foot"
            style={
              this.state.width <= 768
                ? { background: "white" }
                : {
                    paddingTop: "2rem",
                    background:
                      "linear-gradient(90deg, white 50%, transparent 50%)",
                  }
            }
          >
            <div className="container">
              <div className="columns">
                <div className="column is-half">
                  <div
                    className="content"
                    style={
                      this.state.width <= 1023 ? { padding: "1.5rem" } : null
                    }
                  >
                    <p className="title is-family-secondary">{t("ROAD")}</p>
                    <p>{t("ROAD_CLAIM")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div
              className="columns"
              style={{
                borderBottom: "2px solid #E30C5C",
                paddingBottom: "3rem",
              }}
            >
              <div className="column">
                <div className="content">
                  <p>{t("ROAD_ABOUT")}</p>
                </div>
              </div>
              <div id="spedycja-morska" className="column">
                <div className="content">
                  <p className="has-text-primary">{t("ROAD_ABOUT_PINK")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hero is-large is-relative" style={middle_ship}>
          <div className="hero-head">
            <div className="container">
              <div className="columns">
                <div
                  className="column is-half is-offset-6 has-background-white"
                  style={this.state.width >= 1407 ? ship_card : null}
                >
                  <div
                    className="content"
                    style={
                      this.state.width <= 768 ? { padding: "1.5rem" } : null
                    }
                  >
                    <p className="title is-family-secondary">{t("SEA")}</p>
                    <p>{t("SEA_CLAIM")}</p>
                    <p>{t("SEA_PARAGRAPH_2")}</p>
                    <p>{t("SEA_PARAGRAPH_3")}</p>
                    <p>{t("SEA_PARAGRAPH_4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-body">
            <div className="container"></div>
          </div>
          <section className="section pink-section" style={pink_section}>
            <div
              className="container has-background-primary"
              style={{ border: "40px solid #E30C5C" }}
            >
              <div className="columns">
                <div
                  className="column is-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="content">
                    <img src={HowWeMakeItImage} alt="fastigo-how-we-make-it-image"/>
                  </div>
                </div>
                <div className="column">
                  <div className="content">
                    <ul style={{ marginTop: "0" }}>
                      <li
                        className="has-text-white"
                        style={{ marginBottom: "20px" }}
                      >
                        {t("HOW_WE_DO_IT_1")}
                      </li>
                      <li
                        className="has-text-white"
                        style={{ marginBottom: "20px" }}
                      >
                        {t("HOW_WE_DO_IT_2")}
                      </li>
                      <li className="has-text-white">{t("HOW_WE_DO_IT_3")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section
          id="express"
          className="section express-section"
          style={{ paddingTop: "12rem" }}
        >
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="content" style={{ paddingBottom: "2rem" }}>
                  <p className="title is-family-secondary">
                    Express, Door-to-door
                  </p>
                  <p>{t("EXPRESS_TOP_LINE")}</p>
                  <p>{t("EXPRESS_TOP_BOTTOM")}</p>
                </div>
                <div
                  className="content"
                  style={{ paddingTop: "3rem", borderTop: "1px solid #707070" }}
                >
                  <p className="title is-family-secondary">{t("STORAGE")}</p>
                  <p>{t("STORAGE_TEXT")}</p>
                </div>
              </div>
              <div className="column">
                <div className="content">
                  <p className="title is-family-secondary">
                    {t("DOCUMENTS_TO_DOWNLOAD")}
                  </p>
                  <ul style={{ listStyle: "none", marginLeft: "0" }}>
                    <li className="is-flex">
                      <a
                        href="http://fastigo.pl/dokumenty/fastigo_licencja_spedycyjna.jpg"
                        style={{ marginRight: "10px", minWidth: "23px" }}
                        download
                      >
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      <p>{t("TRANSPORT_LICENSE")}</p>
                    </li>
                    <li className="is-flex">
                      <a
                        href="http://fastigo.pl/dokumenty/fastigo_licencja_transportowa_miedzynarogowa.jpg"
                        style={{ marginRight: "10px", minWidth: "23px" }}
                        download
                      >
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      <p>{t("INTERNATIONAL_TRANSPORT_LICENSE")}</p>
                    </li>
                    <li className="is-flex">
                      <a style={{ marginRight: "10px", minWidth: "23px" }}>
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      OCP
                    </li>
                    <li className="is-flex">
                      <a
                        href="http://fastigo.pl/dokumenty/fastigo_wpis.jpg"
                        style={{ marginRight: "10px", minWidth: "23px" }}
                        download
                      >
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      {t("BUSINESS_REGISTER")}
                    </li>
                    <li className="is-flex">
                      <a
                        href="http://fastigo.pl/dokumenty/fastigo_nip.jpg"
                        style={{ marginRight: "10px", minWidth: "23px" }}
                        download
                      >
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      NIP
                    </li>
                    <li className="is-flex">
                      <a
                        href="http://fastigo.pl/dokumenty/fastigo_regon.jpg"
                        style={{ marginRight: "10px", minWidth: "23px" }}
                        download
                      >
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      REGON
                    </li>
                    <li className="is-flex">
                      <a style={{ marginRight: "10px", minWidth: "23px" }}>
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      {t("WASTE_TRANSPORT")}
                    </li>
                    <li className="is-flex">
                      <a style={{ marginRight: "10px", minWidth: "23px" }}>
                        <img src={Download} style={{ minWidth: "23px" }}></img>
                      </a>
                      {t("ANNEX_TO_THE_DECISION")}
                    </li>
                  </ul>
                  <div
                    className="is-flex"
                    style={{
                      justifyContent: "space-around",
                      paddingTop: "2rem",
                    }}
                  >
                    <img src={Clock} />
                    <img src={Truck} />
                    <img src={Like} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default withTranslation("HomePage")(IndexPage)
